var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "animated fadeIn" },
    [
      _c(
        "b-modal",
        {
          ref: "master-invoice-modal",
          staticClass: "modal-dialog",
          attrs: {
            id: "master-invoice-modal",
            "hide-header": "",
            "ok-only": ""
          }
        },
        [
          _c("b-card", [
            _c("div", { staticClass: "row" }, [
              _c(
                "div",
                { staticClass: "col-md-12" },
                [
                  _c("Master-Invoice", {
                    attrs: {
                      propafes: this.afes,
                      propinvoice: this.invoice,
                      onlyInvoice: false
                    }
                  })
                ],
                1
              )
            ])
          ])
        ],
        1
      ),
      _c(
        "b-modal",
        {
          ref: "master-invoice-modal",
          staticClass: "modal-dialog",
          attrs: {
            id: "invoice-modal",
            "no-close-on-backdrop": _vm.showModal,
            size: "xl",
            "hide-header": "",
            "ok-only": ""
          },
          on: {
            ok: function($event) {
              return _vm.submitAfeData()
            }
          }
        },
        [
          _c("b-card", [
            _c("div", { staticClass: "row" }, [
              _c(
                "div",
                { staticClass: "col-md-12" },
                [
                  _c("MAN-Invoice", {
                    attrs: {
                      propinvoice: this.invoice,
                      removeQbyteInvoice: true,
                      propafes: this.afes
                    },
                    on: {
                      editedAfe: function($event) {
                        return _vm.toggleSubmit($event)
                      }
                    }
                  })
                ],
                1
              )
            ])
          ])
        ],
        1
      ),
      _c(
        "b-card",
        { staticClass: "v-select-overflow" },
        [
          _c("InvoiceHeader", {
            attrs: {
              invoice: _vm.invoice,
              highestAfe: _vm.highestAfe,
              serviceData: _vm.serviceData,
              afes: _vm.afes,
              currency: _vm.currency,
              tax: _vm.tax,
              company_name: _vm.company_name
            }
          }),
          _c("div", { staticClass: "mt-2 invoice-status" }, [
            _vm.invoice.accountant_paid === 0
              ? _c("div", [
                  _c("button", { staticClass: "btn-sm btn-success" }, [
                    _c("i", { staticClass: "fa fa-check" }),
                    _vm._v(" AP Team Review")
                  ])
                ])
              : _vm._e(),
            _vm.invoice.accountant_paid === 1
              ? _c("div", [
                  _c("button", { staticClass: "btn-sm btn-success" }, [
                    _c("i", { staticClass: "fa fa-check" }),
                    _vm._v(" Paid")
                  ])
                ])
              : _vm._e(),
            _vm.invoice.qbyte_status != null
              ? _c("div", [
                  _vm.invoice.qbyte_status.code == 0
                    ? _c(
                        "button",
                        {
                          staticClass: "btn-sm mt-2 btn-secondary",
                          staticStyle: { "text-transform": "capitalize" }
                        },
                        [
                          _c("i", { staticClass: "fa fa-question" }),
                          _vm._v(" " + _vm._s(_vm.invoice.qbyte_status.text))
                        ]
                      )
                    : _vm._e(),
                  _vm.invoice.qbyte_status.code == -2
                    ? _c(
                        "button",
                        {
                          staticClass: "btn-sm mt-2 btn-secondary",
                          staticStyle: { "text-transform": "capitalize" }
                        },
                        [
                          _c("i", { staticClass: "fa fa-question" }),
                          _vm._v("Unprocessed")
                        ]
                      )
                    : _vm._e(),
                  _vm.invoice.qbyte_status.code == 1
                    ? _c(
                        "button",
                        {
                          staticClass: "btn-sm mt-2 btn-blue",
                          staticStyle: { "text-transform": "capitalize" }
                        },
                        [
                          _c("i", { staticClass: "fa fa-save" }),
                          _vm._v(" " + _vm._s(_vm.invoice.qbyte_status.text))
                        ]
                      )
                    : _vm._e(),
                  _vm.invoice.qbyte_status.code == 2
                    ? _c(
                        "button",
                        {
                          staticClass: "btn-sm mt-2 btn-primary",
                          staticStyle: { "text-transform": "capitalize" }
                        },
                        [
                          _c("i", { staticClass: "fa fa-check" }),
                          _vm._v(" " + _vm._s(_vm.invoice.qbyte_status.text))
                        ]
                      )
                    : _vm._e(),
                  _vm.invoice.qbyte_status.code == 3
                    ? _c(
                        "button",
                        {
                          staticClass: "btn-sm mt-2 btn-warning",
                          staticStyle: { "text-transform": "capitalize" }
                        },
                        [
                          _c("i", { staticClass: "fa fa-question" }),
                          _vm._v(" " + _vm._s(_vm.invoice.qbyte_status.text))
                        ]
                      )
                    : _vm._e(),
                  _vm.invoice.qbyte_status.code == 4
                    ? _c(
                        "button",
                        {
                          staticClass: "btn-sm mt-2 btn-success",
                          staticStyle: { "text-transform": "capitalize" }
                        },
                        [
                          _c("i", { staticClass: "fa fa-check" }),
                          _vm._v(" " + _vm._s(_vm.invoice.qbyte_status.text))
                        ]
                      )
                    : _vm._e(),
                  _vm.invoice.qbyte_status.code == -3
                    ? _c(
                        "button",
                        {
                          staticClass: "btn-sm mt-2",
                          staticStyle: {
                            "text-transform": "capitalize",
                            "background-color": "dimgray",
                            color: "white"
                          }
                        },
                        [
                          _c("i", { staticClass: "fa fa-trash" }),
                          _vm._v(" " + _vm._s(_vm.invoice.qbyte_status.text))
                        ]
                      )
                    : _vm._e(),
                  _vm.invoice.qbyte_status.code == -5
                    ? _c(
                        "button",
                        {
                          staticClass: "btn-sm mt-2",
                          staticStyle: {
                            "text-transform": "capitalize",
                            "background-color": "crimson",
                            color: "white"
                          }
                        },
                        [
                          _c("i", { staticClass: "fa fa-question" }),
                          _vm._v(" Accountant Deleted")
                        ]
                      )
                    : _vm._e()
                ])
              : _vm._e()
          ]),
          _c(
            "div",
            { staticClass: "mb-4" },
            [
              _c(
                "b-button",
                {
                  directives: [
                    {
                      name: "b-modal",
                      rawName: "v-b-modal.invoice-modal",
                      modifiers: { "invoice-modal": true }
                    }
                  ],
                  staticClass: "pull-right pl-3 btn-sm font-weight-bold",
                  attrs: { variant: "primary" }
                },
                [
                  _c("span", { staticClass: "mt-2 d-none d-sm-inline mr-1" }, [
                    _vm._v("View Invoice ")
                  ]),
                  _c("i", { staticClass: "fas fa-search" })
                ]
              ),
              _c(
                "b-button",
                {
                  directives: [
                    {
                      name: "b-modal",
                      rawName: "v-b-modal.master-invoice-modal",
                      modifiers: { "master-invoice-modal": true }
                    }
                  ],
                  staticClass: "pull-right pl-3 btn-sm font-weight-bold mr-2",
                  attrs: { variant: "primary" }
                },
                [
                  _c("span", { staticClass: "mt-2 d-none d-sm-inline mr-1" }, [
                    _vm._v("View Master Invoice ")
                  ]),
                  _c("i", { staticClass: "fas fa-search" })
                ]
              )
            ],
            1
          ),
          _c("br"),
          _c(
            "b-row",
            { staticClass: "mt-3 clearfix mb-4" },
            [
              _c(
                "b-col",
                [_c("VendorDetails", { attrs: { invoice: _vm.invoice } })],
                1
              ),
              _c(
                "b-col",
                [
                  _c("CompanyDetails", {
                    attrs: {
                      invoice: _vm.invoice,
                      company_name: _vm.company_name
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _c(
            "b-row",
            [
              _c("InvoiceDetails", {
                attrs: {
                  invoice: _vm.invoice,
                  highestAfe: _vm.highestAfe,
                  toggleVselect: false,
                  toggleEditVIN: false
                }
              })
            ],
            1
          ),
          _c("h2", [_vm._v("Details")]),
          _c("RotateSquare2", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: !_vm.invoiceConfigLoading,
                expression: "!invoiceConfigLoading"
              }
            ],
            staticClass: "loading-gif"
          }),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.invoiceConfigLoading,
                  expression: "invoiceConfigLoading"
                }
              ],
              staticClass: "details-box",
              staticStyle: { "background-color": "rgb(242, 242, 242)" }
            },
            [
              _c("div", { staticClass: "row" }, [
                _c(
                  "div",
                  { staticClass: "col-12 col-lg-6" },
                  [
                    _vm._v("Organization:\n          "),
                    _c("v-select", {
                      attrs: {
                        clearable: false,
                        disabled: [2, 3, 4, 5, -5].includes(
                          _vm.invoice.qbyte_status.code
                        ),
                        label: "value_1",
                        options: _vm.org_idOptions
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "option",
                          fn: function(option) {
                            return [
                              option.value_1 != null
                                ? _c("span", [_vm._v(_vm._s(option.value_1))])
                                : _vm._e(),
                              option.value_2 != null
                                ? _c("span", [
                                    _vm._v("-" + _vm._s(option.value_2))
                                  ])
                                : _vm._e(),
                              option.value_3 != null
                                ? _c("span", [
                                    _vm._v("-" + _vm._s(option.value_3))
                                  ])
                                : _vm._e(),
                              option.value_4 != null
                                ? _c("span", [
                                    _vm._v("-" + _vm._s(option.value_4))
                                  ])
                                : _vm._e()
                            ]
                          }
                        },
                        {
                          key: "selected-option",
                          fn: function(ref) {
                            var value_1 = ref.value_1
                            var value_2 = ref.value_2
                            var value_3 = ref.value_3
                            var value_4 = ref.value_4
                            var option = ref.option
                            var deselect = ref.deselect
                            var multiple = ref.multiple
                            var disabled = ref.disabled
                            return [
                              value_1 != null
                                ? _c("span", [_vm._v(_vm._s(value_1))])
                                : _vm._e(),
                              value_2 != null
                                ? _c("span", [_vm._v("-" + _vm._s(value_2))])
                                : _vm._e(),
                              value_3 != null
                                ? _c("span", [_vm._v("-" + _vm._s(value_3))])
                                : _vm._e(),
                              value_4 != null
                                ? _c("span", [_vm._v("-" + _vm._s(value_4))])
                                : _vm._e()
                            ]
                          }
                        }
                      ]),
                      model: {
                        value: _vm.invoiceConfig.org,
                        callback: function($$v) {
                          _vm.$set(_vm.invoiceConfig, "org", $$v)
                        },
                        expression: "invoiceConfig.org"
                      }
                    })
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "col-12 col-lg-6" },
                  [
                    _vm._v("Invoice Type:\n          "),
                    _c("v-select", {
                      staticClass: "mt-1",
                      attrs: {
                        clearable: false,
                        disabled: [2, 3, 4, 5, -5].includes(
                          _vm.invoice.qbyte_status.code
                        ),
                        label: "value_1",
                        options: _vm.invoice_typeOptions
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "option",
                          fn: function(option) {
                            return [
                              option.value_1 != null
                                ? _c("span", [_vm._v(_vm._s(option.value_1))])
                                : _vm._e(),
                              option.value_2 != null
                                ? _c("span", [
                                    _vm._v("-" + _vm._s(option.value_2))
                                  ])
                                : _vm._e(),
                              option.value_3 != null
                                ? _c("span", [
                                    _vm._v("-" + _vm._s(option.value_3))
                                  ])
                                : _vm._e(),
                              option.value_4 != null
                                ? _c("span", [
                                    _vm._v("-" + _vm._s(option.value_4))
                                  ])
                                : _vm._e()
                            ]
                          }
                        },
                        {
                          key: "selected-option",
                          fn: function(ref) {
                            var value_1 = ref.value_1
                            var value_2 = ref.value_2
                            var value_3 = ref.value_3
                            var value_4 = ref.value_4
                            var option = ref.option
                            var deselect = ref.deselect
                            var multiple = ref.multiple
                            var disabled = ref.disabled
                            return [
                              value_1 != null
                                ? _c("span", [_vm._v(_vm._s(value_1))])
                                : _vm._e(),
                              value_2 != null
                                ? _c("span", [_vm._v("-" + _vm._s(value_2))])
                                : _vm._e(),
                              value_3 != null
                                ? _c("span", [_vm._v("-" + _vm._s(value_3))])
                                : _vm._e(),
                              value_4 != null
                                ? _c("span", [_vm._v("-" + _vm._s(value_4))])
                                : _vm._e()
                            ]
                          }
                        }
                      ]),
                      model: {
                        value: _vm.invoiceConfig.invoice_type,
                        callback: function($$v) {
                          _vm.$set(_vm.invoiceConfig, "invoice_type", $$v)
                        },
                        expression: "invoiceConfig.invoice_type"
                      }
                    })
                  ],
                  1
                )
              ]),
              _c("div", { staticClass: "row" }, [
                _c(
                  "div",
                  { staticClass: "col-12 col-lg-6" },
                  [
                    _vm._v("Bus Assoc: "),
                    _vm.lodash.has(
                      _vm.invoiceConfig.business_associate,
                      "is_exact_match"
                    ) &&
                    _vm.lodash.has(
                      _vm.invoiceConfig.business_associate,
                      "is_exact_match"
                    ) &&
                    !_vm.invoiceConfig.business_associate.is_exact_match
                      ? _c("span", { staticClass: "error-match" }, [
                          _c("i", {
                            staticClass: "fa fa-exclamation-triangle"
                          }),
                          _vm._v("This is not an exact match.")
                        ])
                      : _vm._e(),
                    _c("v-select", {
                      staticClass: "mt-1",
                      attrs: {
                        clearable: false,
                        disabled: [2, 3, 4, 5, -5].includes(
                          _vm.invoice.qbyte_status.code
                        ),
                        label: "value_1",
                        options: _vm.invoice_businessAssociateOptions
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "option",
                          fn: function(option) {
                            return [
                              option.value_1 != null
                                ? _c("span", [_vm._v(_vm._s(option.value_1))])
                                : _vm._e(),
                              option.value_2 != null
                                ? _c("span", [
                                    _vm._v("-" + _vm._s(option.value_2))
                                  ])
                                : _vm._e(),
                              option.value_3 != null
                                ? _c("span", [
                                    _vm._v("-" + _vm._s(option.value_3))
                                  ])
                                : _vm._e(),
                              option.value_4 != null
                                ? _c("span", [
                                    _vm._v("-" + _vm._s(option.value_4))
                                  ])
                                : _vm._e()
                            ]
                          }
                        },
                        {
                          key: "selected-option",
                          fn: function(ref) {
                            var value_1 = ref.value_1
                            var value_2 = ref.value_2
                            var value_3 = ref.value_3
                            var value_4 = ref.value_4
                            var option = ref.option
                            var deselect = ref.deselect
                            var multiple = ref.multiple
                            var disabled = ref.disabled
                            return [
                              value_1 != null
                                ? _c("span", [_vm._v(_vm._s(value_1))])
                                : _vm._e(),
                              value_2 != null
                                ? _c("span", [_vm._v("-" + _vm._s(value_2))])
                                : _vm._e(),
                              value_3 != null
                                ? _c("span", [_vm._v("-" + _vm._s(value_3))])
                                : _vm._e(),
                              value_4 != null
                                ? _c("span", [_vm._v("-" + _vm._s(value_4))])
                                : _vm._e()
                            ]
                          }
                        }
                      ]),
                      model: {
                        value: _vm.invoiceConfig.business_associate,
                        callback: function($$v) {
                          _vm.$set(_vm.invoiceConfig, "business_associate", $$v)
                        },
                        expression: "invoiceConfig.business_associate"
                      }
                    })
                  ],
                  1
                ),
                _c("div", { staticClass: "col-12 col-lg-6" }, [
                  _vm._v("Address: "),
                  _c("input", {
                    staticClass: "detail-box-input",
                    attrs: { type: "text", readonly: "" },
                    domProps: {
                      value:
                        _vm.invoice.addressline1 +
                        " " +
                        _vm.invoice.addressline2
                    }
                  })
                ])
              ]),
              _c("div", { staticClass: "row" }, [
                _c("div", { staticClass: "col-12 col-sm-6 col-md-3" }, [
                  _c("div", { staticClass: "row" }, [
                    _c("div", { staticClass: "col-12 col-sm-6 col-md-6" }, [
                      _vm._v("Sep. Cheque:\n              "),
                      _c(
                        "div",
                        { staticClass: "custom-control custom-checkbox" },
                        [
                          [2, 3, 4, 5, -5].includes(
                            _vm.invoice.qbyte_status.code
                          )
                            ? _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.invoiceConfig.is_separate_cheque,
                                    expression:
                                      "invoiceConfig.is_separate_cheque"
                                  }
                                ],
                                staticClass: "custom-control-input",
                                attrs: {
                                  type: "checkbox",
                                  id: "defaultUnchecked",
                                  onclick: "return false"
                                },
                                domProps: {
                                  checked: Array.isArray(
                                    _vm.invoiceConfig.is_separate_cheque
                                  )
                                    ? _vm._i(
                                        _vm.invoiceConfig.is_separate_cheque,
                                        null
                                      ) > -1
                                    : _vm.invoiceConfig.is_separate_cheque
                                },
                                on: {
                                  change: function($event) {
                                    var $$a =
                                        _vm.invoiceConfig.is_separate_cheque,
                                      $$el = $event.target,
                                      $$c = $$el.checked ? true : false
                                    if (Array.isArray($$a)) {
                                      var $$v = null,
                                        $$i = _vm._i($$a, $$v)
                                      if ($$el.checked) {
                                        $$i < 0 &&
                                          _vm.$set(
                                            _vm.invoiceConfig,
                                            "is_separate_cheque",
                                            $$a.concat([$$v])
                                          )
                                      } else {
                                        $$i > -1 &&
                                          _vm.$set(
                                            _vm.invoiceConfig,
                                            "is_separate_cheque",
                                            $$a
                                              .slice(0, $$i)
                                              .concat($$a.slice($$i + 1))
                                          )
                                      }
                                    } else {
                                      _vm.$set(
                                        _vm.invoiceConfig,
                                        "is_separate_cheque",
                                        $$c
                                      )
                                    }
                                  }
                                }
                              })
                            : _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.invoiceConfig.is_separate_cheque,
                                    expression:
                                      "invoiceConfig.is_separate_cheque"
                                  }
                                ],
                                staticClass: "custom-control-input",
                                attrs: {
                                  type: "checkbox",
                                  id: "defaultUnchecked"
                                },
                                domProps: {
                                  checked: Array.isArray(
                                    _vm.invoiceConfig.is_separate_cheque
                                  )
                                    ? _vm._i(
                                        _vm.invoiceConfig.is_separate_cheque,
                                        null
                                      ) > -1
                                    : _vm.invoiceConfig.is_separate_cheque
                                },
                                on: {
                                  change: function($event) {
                                    var $$a =
                                        _vm.invoiceConfig.is_separate_cheque,
                                      $$el = $event.target,
                                      $$c = $$el.checked ? true : false
                                    if (Array.isArray($$a)) {
                                      var $$v = null,
                                        $$i = _vm._i($$a, $$v)
                                      if ($$el.checked) {
                                        $$i < 0 &&
                                          _vm.$set(
                                            _vm.invoiceConfig,
                                            "is_separate_cheque",
                                            $$a.concat([$$v])
                                          )
                                      } else {
                                        $$i > -1 &&
                                          _vm.$set(
                                            _vm.invoiceConfig,
                                            "is_separate_cheque",
                                            $$a
                                              .slice(0, $$i)
                                              .concat($$a.slice($$i + 1))
                                          )
                                      }
                                    } else {
                                      _vm.$set(
                                        _vm.invoiceConfig,
                                        "is_separate_cheque",
                                        $$c
                                      )
                                    }
                                  }
                                }
                              }),
                          _c(
                            "label",
                            {
                              staticClass: "custom-control-label",
                              attrs: { for: "defaultUnchecked" }
                            },
                            [_vm._v("Yes")]
                          )
                        ]
                      )
                    ]),
                    _c("div", { staticClass: "col-12 col-sm-6 col-md-6" }, [
                      _vm._v("Is Quickpay:\n              "),
                      _vm.invoiceConfig.has_quickpay
                        ? _c(
                            "div",
                            { staticClass: "custom-control custom-checkbox" },
                            [
                              _c("input", {
                                staticClass: "custom-control-input",
                                attrs: {
                                  type: "checkbox",
                                  id: "defaultUnchecked2",
                                  disabled: [2, 3, 4, 5, -5].includes(
                                    _vm.invoice.qbyte_status.code
                                  )
                                },
                                domProps: {
                                  checked: _vm.invoiceConfig.has_quickpay
                                },
                                on: {
                                  click: function($event) {
                                    return _vm.isQuickPay()
                                  }
                                }
                              }),
                              _c(
                                "label",
                                {
                                  staticClass: "custom-control-label",
                                  attrs: { for: "defaultUnchecked2" }
                                },
                                [_vm._v("Yes")]
                              )
                            ]
                          )
                        : _c(
                            "div",
                            { staticClass: "custom-control custom-checkbox" },
                            [
                              _c("input", {
                                staticClass: "custom-control-input",
                                attrs: {
                                  type: "checkbox",
                                  disabled: "",
                                  id: "defaultUnchecked2"
                                }
                              }),
                              _c(
                                "label",
                                {
                                  staticClass: "custom-control-label",
                                  attrs: { for: "defaultUnchecked2" }
                                },
                                [_vm._v("No")]
                              )
                            ]
                          )
                    ])
                  ])
                ]),
                _c("div", { staticClass: "col-12 col-sm-6 col-md-3" }, [
                  _vm._v("Created By: "),
                  _c("input", {
                    staticClass: "detail-box-input",
                    attrs: { type: "text", readonly: "" },
                    domProps: {
                      value: _vm.profile.fname + " " + _vm.profile.lname
                    }
                  })
                ]),
                _c("div", { staticClass: "col-12 col-sm-6 col-md-3" }, [
                  _vm._v("Exchange Rate: "),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.invoiceConfig.exchange_rate,
                        expression: "invoiceConfig.exchange_rate"
                      }
                    ],
                    staticClass: "detail-box-input",
                    attrs: {
                      readonly: [2, 3, 4, 5, -5].includes(
                        _vm.invoice.qbyte_status.code
                      ),
                      type: "number",
                      step: "any"
                    },
                    domProps: { value: _vm.invoiceConfig.exchange_rate },
                    on: {
                      input: function($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.$set(
                          _vm.invoiceConfig,
                          "exchange_rate",
                          $event.target.value
                        )
                      }
                    }
                  })
                ]),
                _c(
                  "div",
                  { staticClass: "col-12 col-sm-6 col-md-3" },
                  [
                    _vm._v("Period:\n          "),
                    _c("v-select", {
                      staticClass: "mt-1",
                      attrs: {
                        clearable: false,
                        disabled: [2, 3, 4, 5, -5].includes(
                          _vm.invoice.qbyte_status.code
                        ),
                        label: "line",
                        options: _vm.invoice_periodOptions
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "option",
                          fn: function(option) {
                            return [
                              option.value_1 != null
                                ? _c("span", [_vm._v(_vm._s(option.value_1))])
                                : _vm._e(),
                              option.value_2 != null
                                ? _c("span", [
                                    _vm._v(
                                      "-" +
                                        _vm._s(
                                          _vm
                                            .$moment(option.value_2)
                                            .format("YYYY/MM")
                                        )
                                    )
                                  ])
                                : _vm._e(),
                              option.value_3 != null
                                ? _c("span", [
                                    _vm._v("-" + _vm._s(option.value_3))
                                  ])
                                : _vm._e(),
                              option.value_4 != null
                                ? _c("span", [
                                    _vm._v("-" + _vm._s(option.value_4))
                                  ])
                                : _vm._e()
                            ]
                          }
                        },
                        {
                          key: "selected-option",
                          fn: function(ref) {
                            var value_1 = ref.value_1
                            var value_2 = ref.value_2
                            var value_3 = ref.value_3
                            var value_4 = ref.value_4
                            var option = ref.option
                            var deselect = ref.deselect
                            var multiple = ref.multiple
                            var disabled = ref.disabled
                            return [
                              value_1 != null
                                ? _c("span", [_vm._v(_vm._s(value_1))])
                                : _vm._e(),
                              value_2 != null
                                ? _c("span", [
                                    _vm._v(
                                      "-" +
                                        _vm._s(
                                          _vm.$moment(value_2).format("YYYY/MM")
                                        )
                                    )
                                  ])
                                : _vm._e(),
                              value_3 != null
                                ? _c("span", [_vm._v("-" + _vm._s(value_3))])
                                : _vm._e(),
                              value_4 != null
                                ? _c("span", [_vm._v("-" + _vm._s(value_4))])
                                : _vm._e()
                            ]
                          }
                        }
                      ]),
                      model: {
                        value: _vm.invoiceConfig.accounting_period,
                        callback: function($$v) {
                          _vm.$set(_vm.invoiceConfig, "accounting_period", $$v)
                        },
                        expression: "invoiceConfig.accounting_period"
                      }
                    })
                  ],
                  1
                )
              ]),
              _c("div", { staticClass: "row" }, [
                _c(
                  "div",
                  { staticClass: "col-12 col-sm-6 col-md-3" },
                  [
                    _vm._v("Pay Code: "),
                    [0, 1, -1, -2, -3].includes(
                      _vm.invoice.qbyte_status.code
                    ) &&
                    _vm.invoiceConfig.pay_code != null &&
                    _vm.invoiceConfig.pay_code != ""
                      ? _c("i", {
                          directives: [
                            {
                              name: "b-tooltip",
                              rawName: "v-b-tooltip.hover",
                              modifiers: { hover: true }
                            }
                          ],
                          staticClass: "hover-mouse fa fa-times-circle",
                          attrs: { title: "Remove" },
                          on: {
                            click: function($event) {
                              _vm.invoiceConfig.pay_code = null
                            }
                          }
                        })
                      : _vm._e(),
                    _c("v-select", {
                      staticClass: "mt-1",
                      attrs: {
                        clearable: false,
                        disabled: [2, 3, 4, 5, -5].includes(
                          _vm.invoice.qbyte_status.code
                        ),
                        label: "value_1",
                        options: _vm.pay_codeOptions
                      },
                      model: {
                        value: _vm.invoiceConfig.pay_code,
                        callback: function($$v) {
                          _vm.$set(_vm.invoiceConfig, "pay_code", $$v)
                        },
                        expression: "invoiceConfig.pay_code"
                      }
                    })
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "col-12 col-sm-6 col-md-3" },
                  [
                    _vm._v("PO/PA#:\n          "),
                    _c("v-select", {
                      staticClass: "mt-1",
                      attrs: {
                        clearable: false,
                        disabled: [2, 3, 4, 5, -5].includes(
                          _vm.invoice.qbyte_status.code
                        ),
                        label: "value_1",
                        options: _vm.purchase_numOptions
                      },
                      model: {
                        value: _vm.invoiceConfig.purchase_num,
                        callback: function($$v) {
                          _vm.$set(_vm.invoiceConfig, "purchase_num", $$v)
                        },
                        expression: "invoiceConfig.purchase_num"
                      }
                    })
                  ],
                  1
                ),
                _c("div", { staticClass: "col-12 col-sm-6 col-md-3" }, [
                  _vm._v("Date: "),
                  _c("input", {
                    directives: [
                      {
                        name: "b-popover",
                        rawName: "v-b-popover",
                        value: "YYYY/MM/DD",
                        expression: "'YYYY/MM/DD'"
                      },
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.invoiceConfig.invoice_date,
                        expression: "invoiceConfig.invoice_date"
                      }
                    ],
                    staticClass: "detail-box-input",
                    attrs: { type: "text", title: "Input Date Format" },
                    domProps: { value: _vm.invoiceConfig.invoice_date },
                    on: {
                      input: function($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.$set(
                          _vm.invoiceConfig,
                          "invoice_date",
                          $event.target.value
                        )
                      }
                    }
                  })
                ]),
                _c(
                  "div",
                  { staticClass: "col-12 col-sm-6 col-md-3" },
                  [
                    _vm._v("Vendor Invoice Number:\n          "),
                    _c(
                      "b-input-group",
                      { staticClass: "mb-3", attrs: { size: "md" } },
                      [
                        _c("b-form-input", {
                          staticStyle: { "background-color": "white" },
                          attrs: { type: "text", readonly: _vm.isEditInvoice },
                          model: {
                            value: _vm.invoiceConfig.vendor_invoice_num,
                            callback: function($$v) {
                              _vm.$set(
                                _vm.invoiceConfig,
                                "vendor_invoice_num",
                                $$v
                              )
                            },
                            expression: "invoiceConfig.vendor_invoice_num"
                          }
                        }),
                        _c(
                          "b-input-group-append",
                          [
                            !_vm.isEditInvoice
                              ? _c(
                                  "b-button",
                                  {
                                    staticStyle: { "margin-top": "5px" },
                                    attrs: {
                                      size: "md",
                                      text: "Button",
                                      variant: "danger"
                                    },
                                    on: {
                                      click: function($event) {
                                        _vm.isEditInvoice = !_vm.isEditInvoice
                                        _vm.invoiceConfig.vendor_invoice_num =
                                          _vm.oldVin
                                      }
                                    }
                                  },
                                  [_vm._v("X")]
                                )
                              : _vm._e(),
                            !_vm.isEditInvoice
                              ? _c(
                                  "b-button",
                                  {
                                    staticStyle: { "margin-top": "5px" },
                                    attrs: {
                                      size: "md",
                                      text: "Button",
                                      variant: "success"
                                    },
                                    on: {
                                      click: function($event) {
                                        return _vm.editVendorInvoiceNumber()
                                      }
                                    }
                                  },
                                  [_vm._v("✔")]
                                )
                              : _vm._e(),
                            _vm.isEditInvoice
                              ? _c(
                                  "b-button",
                                  {
                                    staticStyle: { "margin-top": "5px" },
                                    attrs: {
                                      size: "md",
                                      text: "Button",
                                      variant: "primary",
                                      disabled: [2, 3, 4, 5, -5].includes(
                                        _vm.invoice.qbyte_status.code
                                      )
                                    },
                                    on: {
                                      click: function($event) {
                                        _vm.isEditInvoice = !_vm.isEditInvoice
                                      }
                                    }
                                  },
                                  [_c("i", { staticClass: "fa fa-edit" })]
                                )
                              : _vm._e()
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              ]),
              _c("div", { staticClass: "row" }, [
                _c("div", { staticClass: "col-12 col-sm-6 col-md-3" }, [
                  _vm._v("Discount Percentage %:"),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.discount_percentage,
                        expression: "discount_percentage"
                      }
                    ],
                    staticClass: "detail-box-input",
                    attrs: { readonly: "", type: "number", step: "any" },
                    domProps: { value: _vm.discount_percentage },
                    on: {
                      input: [
                        function($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.discount_percentage = $event.target.value
                        },
                        function($event) {
                          return _vm.changeDiscount(_vm.invoiceConfig.subtotal)
                        }
                      ]
                    }
                  })
                ]),
                _c("div", { staticClass: "col-12 col-sm-6 col-md-3" }, [
                  _vm._v("Discount Amount:"),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.discount_amount,
                        expression: "discount_amount"
                      }
                    ],
                    staticClass: "detail-box-input",
                    attrs: { readonly: "", type: "number", step: "any" },
                    domProps: { value: _vm.discount_amount },
                    on: {
                      input: [
                        function($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.discount_amount = $event.target.value
                        },
                        function($event) {
                          return _vm.changeAmount(_vm.invoiceConfig.subtotal)
                        }
                      ]
                    }
                  })
                ]),
                _c(
                  "div",
                  { staticClass: "col-12 col-sm-6 col-md-3" },
                  [
                    _vm._v("Hold Date\n          "),
                    [0, 1, -1, -2, -3].includes(_vm.invoice.qbyte_status.code)
                      ? _c("v-date-picker", {
                          staticClass: "vc-calendar",
                          attrs: { formats: _vm.formats, "show-caps": "" },
                          model: {
                            value: _vm.invoiceConfig.hold_date,
                            callback: function($$v) {
                              _vm.$set(_vm.invoiceConfig, "hold_date", $$v)
                            },
                            expression: "invoiceConfig.hold_date"
                          }
                        })
                      : _vm._e(),
                    [2, 3, 4, 5, -5].includes(_vm.invoice.qbyte_status.code)
                      ? _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.invoiceConfig.hold_date,
                              expression: "invoiceConfig.hold_date"
                            }
                          ],
                          attrs: { type: "text", readonly: "" },
                          domProps: { value: _vm.invoiceConfig.hold_date },
                          on: {
                            input: function($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.$set(
                                _vm.invoiceConfig,
                                "hold_date",
                                $event.target.value
                              )
                            }
                          }
                        })
                      : _vm._e()
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "col-12 col-sm-6 col-md-3" },
                  [
                    _vm._v("Due. Date:\n          "),
                    [0, 1, -1, -2, -3].includes(_vm.invoice.qbyte_status.code)
                      ? _c("v-date-picker", {
                          staticClass: "vc-calendar",
                          attrs: { formats: _vm.formats, "show-caps": "" },
                          model: {
                            value: _vm.invoiceConfig.due_date,
                            callback: function($$v) {
                              _vm.$set(_vm.invoiceConfig, "due_date", $$v)
                            },
                            expression: "invoiceConfig.due_date"
                          }
                        })
                      : _vm._e(),
                    [2, 3, 4, 5, -5].includes(_vm.invoice.qbyte_status.code)
                      ? _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.invoiceConfig.due_date,
                              expression: "invoiceConfig.due_date"
                            }
                          ],
                          attrs: { type: "text", readonly: "" },
                          domProps: { value: _vm.invoiceConfig.due_date },
                          on: {
                            input: function($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.$set(
                                _vm.invoiceConfig,
                                "due_date",
                                $event.target.value
                              )
                            }
                          }
                        })
                      : _vm._e()
                  ],
                  1
                )
              ])
            ]
          ),
          _c(
            "h2",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.invoiceConfigLoading,
                  expression: "invoiceConfigLoading"
                }
              ],
              staticClass: "mt-4"
            },
            [_vm._v("Taxes")]
          ),
          _vm.invoiceConfig
            ? _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.invoiceConfigLoading,
                      expression: "invoiceConfigLoading"
                    }
                  ]
                },
                [
                  _c(
                    "div",
                    { staticClass: "row mb-3 text-center d-none d-xl-flex" },
                    [
                      [0, 1, -1, -2, -3].includes(_vm.invoice.qbyte_status.code)
                        ? _c("div", { staticClass: "col-xl-1" }, [
                            _vm._v("\n           \n        ")
                          ])
                        : _vm._e(),
                      _c("div", { staticClass: "col-xl-2" }, [
                        _c("strong", [_vm._v("Tax Name")])
                      ]),
                      _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: false,
                              expression: "false"
                            }
                          ],
                          staticClass: "col-xl-2"
                        },
                        [_c("strong", [_vm._v("Tax %")])]
                      ),
                      _c("div", { staticClass: "col-xl-1" }, [
                        _c("strong", [_vm._v("Amount")])
                      ]),
                      _c("div", { staticClass: "col-xl-3" }, [
                        _c(
                          "strong",
                          { staticStyle: { "margin-right": "51px" } },
                          [
                            _vm.invoiceConfig.is_quickpay
                              ? _c("span", [
                                  _vm._v("Quickpay Tax\n              Amount")
                                ])
                              : _vm._e()
                          ]
                        )
                      ]),
                      _c("div", { staticClass: "col-xl-2" }, [
                        _c("strong", [_vm._v("Cost Code")])
                      ])
                    ]
                  ),
                  _vm._l(_vm.taxes, function(line, index) {
                    return _c(
                      "div",
                      { staticClass: "row mb-2", attrs: { id: "taxes" } },
                      [
                        [0, 1, -1, -2, -3].includes(
                          _vm.invoice.qbyte_status.code
                        )
                          ? _c("div", { staticClass: "col-xl-1 text-center" }, [
                              _c(
                                "h6",
                                { staticClass: "mt-2 d-block d-xl-none" },
                                [_vm._v("Remove")]
                              ),
                              [0, 1, -1, -2, -3].includes(
                                _vm.invoice.qbyte_status.code
                              )
                                ? _c("i", {
                                    directives: [
                                      {
                                        name: "b-tooltip",
                                        rawName: "v-b-tooltip.hover",
                                        modifiers: { hover: true }
                                      }
                                    ],
                                    staticClass: "fa fa-minus-circle mt-2",
                                    staticStyle: {
                                      color: "#f86c6b",
                                      "font-weight": "bold"
                                    },
                                    attrs: { title: "Remove" },
                                    on: {
                                      click: function($event) {
                                        return _vm.taxes.splice(index, 1)
                                      }
                                    }
                                  })
                                : _vm._e()
                            ])
                          : _vm._e(),
                        _c("div", { staticClass: "col-xl-2" }, [
                          _c("h6", { staticClass: "mt-2 d-block d-xl-none" }, [
                            _vm._v("Tax Name")
                          ]),
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: line.name,
                                expression: "line.name"
                              }
                            ],
                            staticClass: "form-control",
                            attrs: {
                              type: "text",
                              readonly: [2, 3, 4, 5, -5].includes(
                                _vm.invoice.qbyte_status.code
                              ),
                              placeholder: "Name"
                            },
                            domProps: { value: line.name },
                            on: {
                              input: function($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.$set(line, "name", $event.target.value)
                              }
                            }
                          })
                        ]),
                        _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: false,
                                expression: "false"
                              }
                            ],
                            staticClass: "col-xl-2"
                          },
                          [
                            _c(
                              "h6",
                              { staticClass: "mt-2 d-block d-xl-none" },
                              [_vm._v("Tax %")]
                            ),
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: line.percentage,
                                  expression: "line.percentage"
                                }
                              ],
                              staticClass: "form-control",
                              attrs: {
                                type: "number",
                                readonly: [2, 3, 4, 5, -5].includes(
                                  _vm.invoice.qbyte_status.code
                                ),
                                step: "any",
                                max: "100",
                                placeholder: "%"
                              },
                              domProps: { value: line.percentage },
                              on: {
                                input: [
                                  function($event) {
                                    if ($event.target.composing) {
                                      return
                                    }
                                    _vm.$set(
                                      line,
                                      "percentage",
                                      $event.target.value
                                    )
                                  },
                                  function($event) {
                                    return _vm.setAmount(
                                      line,
                                      line.percentage,
                                      _vm.subtotal,
                                      index,
                                      _vm.quickpay_subtotal
                                    )
                                  }
                                ]
                              }
                            })
                          ]
                        ),
                        _c("div", { staticClass: "col-xl-3" }, [
                          _c("h6", { staticClass: "mt-2 d-block d-xl-none" }, [
                            _vm._v(" Tax Amount")
                          ]),
                          _c("div", { staticClass: "row" }, [
                            _c(
                              "div",
                              {
                                class: {
                                  "col-6": _vm.invoiceConfig.is_quickpay,
                                  "col-12": !_vm.invoiceConfig.is_quickpay
                                }
                              },
                              [
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: line.amount,
                                      expression: "line.amount"
                                    }
                                  ],
                                  staticClass: "form-control",
                                  attrs: {
                                    type: "text",
                                    readonly: [2, 3, 4, 5, -5].includes(
                                      _vm.invoice.qbyte_status.code
                                    ),
                                    placeholder: "Amount"
                                  },
                                  domProps: { value: line.amount },
                                  on: {
                                    input: [
                                      function($event) {
                                        if ($event.target.composing) {
                                          return
                                        }
                                        _vm.$set(
                                          line,
                                          "amount",
                                          $event.target.value
                                        )
                                      },
                                      function($event) {
                                        return _vm.setTaxPercent(
                                          line,
                                          line.amount,
                                          _vm.subtotal,
                                          index,
                                          _vm.quickpay_subtotal
                                        )
                                      }
                                    ]
                                  }
                                })
                              ]
                            ),
                            _vm.invoiceConfig.is_quickpay
                              ? _c("div", { staticClass: "col-6" }, [
                                  _c(
                                    "h6",
                                    { staticClass: "mt-2 d-block d-xl-none" },
                                    [
                                      _vm.invoiceConfig.is_quickpay
                                        ? _c("span", [
                                            _vm._v("QuickPay Tax Amount")
                                          ])
                                        : _vm._e()
                                    ]
                                  ),
                                  line.quickpay_subtotal != undefined
                                    ? _c("input", {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value: line.quickpay_amount,
                                            expression: "line.quickpay_amount"
                                          }
                                        ],
                                        staticClass: "form-control",
                                        attrs: {
                                          type: "text",
                                          readonly: "",
                                          placeholder: "Amount"
                                        },
                                        domProps: {
                                          value: line.quickpay_amount
                                        },
                                        on: {
                                          input: function($event) {
                                            if ($event.target.composing) {
                                              return
                                            }
                                            _vm.$set(
                                              line,
                                              "quickpay_amount",
                                              $event.target.value
                                            )
                                          }
                                        }
                                      })
                                    : _c("input", {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value: line.quickpay_amount,
                                            expression: "line.quickpay_amount"
                                          }
                                        ],
                                        staticClass: "form-control",
                                        attrs: {
                                          type: "text",
                                          readonly: "",
                                          placeholder: "Amount"
                                        },
                                        domProps: {
                                          value: line.quickpay_amount
                                        },
                                        on: {
                                          input: function($event) {
                                            if ($event.target.composing) {
                                              return
                                            }
                                            _vm.$set(
                                              line,
                                              "quickpay_amount",
                                              $event.target.value
                                            )
                                          }
                                        }
                                      })
                                ])
                              : _vm._e()
                          ])
                        ]),
                        _c(
                          "div",
                          {
                            class: {
                              "col-xl-6": [0, 1, -1, -2, -3].includes(
                                _vm.invoice.qbyte_status.code
                              ),
                              "col-xl-6": [2, 3, 4, 5, -5].includes(
                                _vm.invoice.qbyte_status.code
                              )
                            }
                          },
                          [
                            _c(
                              "h6",
                              { staticClass: "mt-2 d-block d-xl-none" },
                              [_vm._v("Cost Code")]
                            ),
                            _c("div", { staticClass: "row" }, [
                              _c("div", { staticClass: "col-1 text-right" }),
                              _c(
                                "div",
                                {
                                  class: {
                                    "col-11": [0, 1, -1, -2, -3].includes(
                                      _vm.invoice.qbyte_status.code
                                    ),
                                    "col-12": [2, 3, 4, 5, -5].includes(
                                      _vm.invoice.qbyte_status.code
                                    )
                                  }
                                },
                                [
                                  _c("v-select", {
                                    attrs: {
                                      clearable: false,
                                      disabled: [2, 3, 4, 5, -5].includes(
                                        _vm.invoice.qbyte_status.code
                                      ),
                                      options: _vm.paginated,
                                      filterable: false,
                                      label: "line"
                                    },
                                    on: {
                                      open: _vm.onOpen,
                                      close: _vm.onClose,
                                      search: function(query) {
                                        return (_vm.search = query)
                                      }
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "list-footer",
                                          fn: function() {
                                            return [
                                              _c(
                                                "li",
                                                {
                                                  ref: "load",
                                                  refInFor: true,
                                                  staticClass: "loader"
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                    Loading more options...\n                  "
                                                  )
                                                ]
                                              )
                                            ]
                                          },
                                          proxy: true
                                        },
                                        {
                                          key: "selected-option",
                                          fn: function(ref) {
                                            var value_1 = ref.value_1
                                            var value_2 = ref.value_2
                                            var value_3 = ref.value_3
                                            var option = ref.option
                                            var deselect = ref.deselect
                                            var multiple = ref.multiple
                                            var disabled = ref.disabled
                                            return [
                                              value_1 != null
                                                ? _c("span", [
                                                    _vm._v(_vm._s(value_1))
                                                  ])
                                                : _vm._e(),
                                              value_2 != null
                                                ? _c("span", [
                                                    _vm._v(
                                                      "-" + _vm._s(value_2)
                                                    )
                                                  ])
                                                : _vm._e(),
                                              value_3 != null
                                                ? _c("span", [
                                                    _vm._v(
                                                      "-" + _vm._s(value_3)
                                                    )
                                                  ])
                                                : _vm._e()
                                            ]
                                          }
                                        }
                                      ],
                                      null,
                                      true
                                    ),
                                    model: {
                                      value: line.account,
                                      callback: function($$v) {
                                        _vm.$set(line, "account", $$v)
                                      },
                                      expression: "line.account"
                                    }
                                  })
                                ],
                                1
                              )
                            ])
                          ]
                        )
                      ]
                    )
                  }),
                  [0, 1, -1, -2, -3].includes(_vm.invoice.qbyte_status.code)
                    ? _c(
                        "button",
                        {
                          staticClass: "btn btn-success mt-2 mb-4",
                          on: {
                            click: function($event) {
                              return _vm.taxes.push({
                                name: "",
                                percentage: 0.0,
                                amount: 0,
                                code: _vm.paginated[0]
                              })
                            }
                          }
                        },
                        [
                          _vm._v("Add New Line "),
                          _c("i", { staticClass: "fa fa-plus" })
                        ]
                      )
                    : _vm._e()
                ],
                2
              )
            : _vm._e(),
          _c(
            "h2",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.invoiceConfigLoading,
                  expression: "invoiceConfigLoading"
                }
              ],
              staticClass: "mt-4 clearfix"
            },
            [_vm._v("AFE'S")]
          ),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.invoiceConfigLoading,
                  expression: "invoiceConfigLoading"
                }
              ],
              staticClass: "table-responsive-sm mt-2"
            },
            _vm._l(_vm.chains, function(line, aIndex) {
              return _c(
                "table",
                {
                  key: line.id,
                  staticClass: "table no-bg afenum mb-0",
                  staticStyle: { "border-top": "3px solid #000" }
                },
                [
                  _c("thead", { staticClass: "rt-border" }, [
                    _c("tr", { staticClass: "afe-rows" }, [
                      _c(
                        "th",
                        {
                          staticClass: "center",
                          staticStyle: { width: "600px" }
                        },
                        [_c("strong", [_vm._v("AFE / PO")])]
                      ),
                      _c(
                        "th",
                        {
                          staticClass: "center",
                          staticStyle: { width: "600px" }
                        },
                        [_vm._v("Cost Center")]
                      ),
                      _c(
                        "th",
                        {
                          staticClass: "center",
                          staticStyle: { width: "400px" }
                        },
                        [_c("strong", [_vm._v("Cost Code")])]
                      )
                    ])
                  ]),
                  _c("tbody", [
                    _c("tr", [
                      _c("td", { staticClass: "width: 600px;" }, [
                        _c("div", { staticClass: "input-group mt-2" }, [
                          _c("input", {
                            staticClass: "form-control",
                            attrs: { type: "text", readonly: "" },
                            domProps: { value: line.afe_num }
                          }),
                          _c("div", { staticClass: "input-group-append" }, [
                            _c("span", { staticClass: "input-group-text" }, [
                              _vm._v("DESC "),
                              _c("i", {
                                directives: [
                                  {
                                    name: "b-tooltip",
                                    rawName: "v-b-tooltip.hover",
                                    modifiers: { hover: true }
                                  }
                                ],
                                staticClass: "ml-2 fa fa-question-circle",
                                attrs: { title: line.afe_desc }
                              })
                            ]),
                            _c("span", { staticClass: "input-group-text" }, [
                              _vm._v("UWI "),
                              _c("i", {
                                directives: [
                                  {
                                    name: "b-tooltip",
                                    rawName: "v-b-tooltip.hover",
                                    modifiers: { hover: true }
                                  }
                                ],
                                staticClass: "ml-2 fa fa-question-circle",
                                attrs: { title: line.well_uwi }
                              })
                            ])
                          ])
                        ])
                      ]),
                      _c("td", { staticStyle: { width: "600px" } }, [
                        _c("div", { staticClass: "input-group mt-2" }, [
                          _c("input", {
                            staticClass: "form-control",
                            attrs: { type: "text", readonly: "" },
                            domProps: { value: line.cost_center_num }
                          }),
                          _c("div", { staticClass: "input-group-append" }, [
                            _c("span", { staticClass: "input-group-text" }, [
                              _vm._v(_vm._s(line.cost_center_percentage) + "%")
                            ])
                          ])
                        ])
                      ]),
                      _c("td", { staticStyle: { width: "400px" } }, [
                        _vm._v(
                          "\n              " +
                            _vm._s(line.ccode_one) +
                            " - " +
                            _vm._s(line.ccode_two) +
                            " - "
                        ),
                        typeof line.ccode_three == "string" &&
                        !line.ccode_three.includes("---")
                          ? _c("span", [_vm._v(_vm._s(line.ccode_three))])
                          : _vm._e()
                      ])
                    ]),
                    _c("tr", [
                      line.account && line.account.value_5 != "N"
                        ? _c(
                            "td",
                            { staticStyle: { width: "600px" } },
                            [
                              _c("strong", [_vm._v("Remote AFE")]),
                              line.remote_afe == null ||
                              (_vm.lodash.has(
                                line.remote_afe,
                                "is_exact_match"
                              ) &&
                                !line.remote_afe.is_exact_match)
                                ? _c("span", { staticClass: "error-match" }, [
                                    _c("i", {
                                      staticClass: "fa fa-exclamation-triangle"
                                    }),
                                    _vm._v("This is not an exact match.")
                                  ])
                                : _vm._e(),
                              _c("v-select", {
                                ref: "remote_afe_" + aIndex,
                                refInFor: true,
                                staticClass: "mt-2 clear-allow",
                                attrs: {
                                  disabled: [2, 3, 4, 5, -5].includes(
                                    _vm.invoice.qbyte_status.code
                                  ),
                                  clearable: true,
                                  label: "value_1",
                                  options: _vm.invoice_afeOptions
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "option",
                                      fn: function(option) {
                                        return [
                                          option.value_1 != null
                                            ? _c("span", [
                                                _vm._v(_vm._s(option.value_1))
                                              ])
                                            : _vm._e(),
                                          option.value_2 != null
                                            ? _c("span", [
                                                _vm._v(
                                                  "-" + _vm._s(option.value_2)
                                                )
                                              ])
                                            : _vm._e(),
                                          option.value_3 != null
                                            ? _c("span", [
                                                _vm._v(
                                                  "-" + _vm._s(option.value_3)
                                                )
                                              ])
                                            : _vm._e(),
                                          option.value_4 != null
                                            ? _c("span", [
                                                _vm._v(
                                                  "-" + _vm._s(option.value_4)
                                                )
                                              ])
                                            : _vm._e()
                                        ]
                                      }
                                    },
                                    {
                                      key: "selected-option",
                                      fn: function(ref) {
                                        var value_1 = ref.value_1
                                        var value_2 = ref.value_2
                                        var value_3 = ref.value_3
                                        var value_4 = ref.value_4
                                        var option = ref.option
                                        var deselect = ref.deselect
                                        var multiple = ref.multiple
                                        var disabled = ref.disabled
                                        return [
                                          value_1 != null
                                            ? _c("span", [
                                                _vm._v(_vm._s(value_1))
                                              ])
                                            : _vm._e(),
                                          value_2 != null
                                            ? _c("span", [
                                                _vm._v("-" + _vm._s(value_2))
                                              ])
                                            : _vm._e(),
                                          value_3 != null
                                            ? _c("span", [
                                                _vm._v("-" + _vm._s(value_3))
                                              ])
                                            : _vm._e(),
                                          value_4 != null
                                            ? _c("span", [
                                                _vm._v("-" + _vm._s(value_4))
                                              ])
                                            : _vm._e()
                                        ]
                                      }
                                    }
                                  ],
                                  null,
                                  true
                                ),
                                model: {
                                  value: line.remote_afe,
                                  callback: function($$v) {
                                    _vm.$set(line, "remote_afe", $$v)
                                  },
                                  expression: "line.remote_afe"
                                }
                              })
                            ],
                            1
                          )
                        : _c(
                            "td",
                            { staticStyle: { width: "600px" } },
                            [
                              _c("strong", [_vm._v("Remote AFE")]),
                              line.remote_afe == null ||
                              (_vm.lodash.has(
                                line.remote_afe,
                                "is_exact_match"
                              ) &&
                                !line.remote_afe.is_exact_match)
                                ? _c("span", { staticClass: "error-match" }, [
                                    _c("i", {
                                      staticClass: "fa fa-exclamation-triangle"
                                    }),
                                    _vm._v("This is not an exact match.")
                                  ])
                                : _vm._e(),
                              _c("v-select", {
                                ref: "remote_afe_" + aIndex,
                                refInFor: true,
                                staticClass: "mt-2 clear-allow",
                                attrs: {
                                  disabled: [2, 3, 4, 5, -5].includes(
                                    _vm.invoice.qbyte_status.code
                                  ),
                                  clearable: true,
                                  label: "value_1",
                                  options: _vm.invoice_afeOptions
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "option",
                                      fn: function(option) {
                                        return [
                                          option.value_1 != null
                                            ? _c("span", [
                                                _vm._v(_vm._s(option.value_1))
                                              ])
                                            : _vm._e(),
                                          option.value_2 != null
                                            ? _c("span", [
                                                _vm._v(
                                                  "-" + _vm._s(option.value_2)
                                                )
                                              ])
                                            : _vm._e(),
                                          option.value_3 != null
                                            ? _c("span", [
                                                _vm._v(
                                                  "-" + _vm._s(option.value_3)
                                                )
                                              ])
                                            : _vm._e(),
                                          option.value_4 != null
                                            ? _c("span", [
                                                _vm._v(
                                                  "-" + _vm._s(option.value_4)
                                                )
                                              ])
                                            : _vm._e()
                                        ]
                                      }
                                    },
                                    {
                                      key: "selected-option",
                                      fn: function(ref) {
                                        var value_1 = ref.value_1
                                        var value_2 = ref.value_2
                                        var value_3 = ref.value_3
                                        var value_4 = ref.value_4
                                        var option = ref.option
                                        var deselect = ref.deselect
                                        var multiple = ref.multiple
                                        var disabled = ref.disabled
                                        return [
                                          value_1 != null
                                            ? _c("span", [
                                                _vm._v(_vm._s(value_1))
                                              ])
                                            : _vm._e(),
                                          value_2 != null
                                            ? _c("span", [
                                                _vm._v("-" + _vm._s(value_2))
                                              ])
                                            : _vm._e(),
                                          value_3 != null
                                            ? _c("span", [
                                                _vm._v("-" + _vm._s(value_3))
                                              ])
                                            : _vm._e(),
                                          value_4 != null
                                            ? _c("span", [
                                                _vm._v("-" + _vm._s(value_4))
                                              ])
                                            : _vm._e()
                                        ]
                                      }
                                    }
                                  ],
                                  null,
                                  true
                                ),
                                model: {
                                  value: _vm.remote_afe_obj.value_1,
                                  callback: function($$v) {
                                    _vm.$set(_vm.remote_afe_obj, "value_1", $$v)
                                  },
                                  expression: "remote_afe_obj.value_1"
                                }
                              })
                            ],
                            1
                          ),
                      _c(
                        "td",
                        { staticStyle: { width: "600px" } },
                        [
                          _c("strong", [_vm._v("Remote Cost Center")]),
                          line.remote_cost_center == null ||
                          (_vm.lodash.has(
                            line.remote_cost_center,
                            "is_exact_match"
                          ) &&
                            !line.remote_cost_center.is_exact_match)
                            ? _c("span", { staticClass: "error-match" }, [
                                _c("i", {
                                  staticClass: "fa fa-exclamation-triangle"
                                }),
                                _vm._v("This is not an exact match.")
                              ])
                            : _vm._e(),
                          _c("v-select", {
                            staticClass: "mt-2",
                            attrs: {
                              clearable: false,
                              disabled: [2, 3, 4, 5, -5].includes(
                                _vm.invoice.qbyte_status.code
                              ),
                              label: "value_1",
                              options: _vm.invoice_costCenterOptions
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "option",
                                  fn: function(option) {
                                    return [
                                      option.value_1 != null
                                        ? _c("span", [
                                            _vm._v(_vm._s(option.value_1))
                                          ])
                                        : _vm._e(),
                                      option.value_2 != null
                                        ? _c("span", [
                                            _vm._v("-" + _vm._s(option.value_2))
                                          ])
                                        : _vm._e(),
                                      option.value_3 != null
                                        ? _c("span", [
                                            _vm._v("-" + _vm._s(option.value_3))
                                          ])
                                        : _vm._e(),
                                      option.value_4 != null
                                        ? _c("span", [
                                            _vm._v("-" + _vm._s(option.value_4))
                                          ])
                                        : _vm._e()
                                    ]
                                  }
                                },
                                {
                                  key: "selected-option",
                                  fn: function(ref) {
                                    var value_1 = ref.value_1
                                    var value_2 = ref.value_2
                                    var value_3 = ref.value_3
                                    var value_4 = ref.value_4
                                    var option = ref.option
                                    var deselect = ref.deselect
                                    var multiple = ref.multiple
                                    var disabled = ref.disabled
                                    return [
                                      value_1 != null
                                        ? _c("span", [_vm._v(_vm._s(value_1))])
                                        : _vm._e(),
                                      value_2 != null
                                        ? _c("span", [
                                            _vm._v("-" + _vm._s(value_2))
                                          ])
                                        : _vm._e(),
                                      value_3 != null
                                        ? _c("span", [
                                            _vm._v("-" + _vm._s(value_3))
                                          ])
                                        : _vm._e(),
                                      value_4 != null
                                        ? _c("span", [
                                            _vm._v("-" + _vm._s(value_4))
                                          ])
                                        : _vm._e()
                                    ]
                                  }
                                }
                              ],
                              null,
                              true
                            ),
                            model: {
                              value: line.remote_cost_center,
                              callback: function($$v) {
                                _vm.$set(line, "remote_cost_center", $$v)
                              },
                              expression: "line.remote_cost_center"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "td",
                        [
                          _c("strong", [_vm._v("Remote Cost Code")]),
                          line.account == null ||
                          (_vm.lodash.has(line.account, "is_exact_match") &&
                            !line.account.is_exact_match)
                            ? _c("span", { staticClass: "error-match" }, [
                                _c("i", {
                                  staticClass: "fa fa-exclamation-triangle"
                                }),
                                _vm._v("This is not an exact match.")
                              ])
                            : _vm._e(),
                          _c("v-select", {
                            staticClass: "mt-2",
                            attrs: {
                              clearable: false,
                              disabled: [2, 3, 4, 5, -5].includes(
                                _vm.invoice.qbyte_status.code
                              ),
                              options: _vm.paginated,
                              filterable: false,
                              label: "line"
                            },
                            on: {
                              open: _vm.onOpen,
                              close: _vm.onClose,
                              search: function(query) {
                                return (_vm.search = query)
                              }
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "list-footer",
                                  fn: function() {
                                    return [
                                      _c(
                                        "li",
                                        {
                                          ref: "load",
                                          refInFor: true,
                                          staticClass: "loader"
                                        },
                                        [
                                          _vm._v(
                                            "\n                    Loading more options...\n                  "
                                          )
                                        ]
                                      )
                                    ]
                                  },
                                  proxy: true
                                },
                                {
                                  key: "selected-option",
                                  fn: function(ref) {
                                    var value_1 = ref.value_1
                                    var value_2 = ref.value_2
                                    var value_3 = ref.value_3
                                    var option = ref.option
                                    var deselect = ref.deselect
                                    var multiple = ref.multiple
                                    var disabled = ref.disabled
                                    return [
                                      value_1 != null
                                        ? _c("span", [_vm._v(_vm._s(value_1))])
                                        : _vm._e(),
                                      value_2 != null
                                        ? _c("span", [
                                            _vm._v("-" + _vm._s(value_2))
                                          ])
                                        : _vm._e(),
                                      value_3 != null
                                        ? _c("span", [
                                            _vm._v("-" + _vm._s(value_3))
                                          ])
                                        : _vm._e()
                                    ]
                                  }
                                }
                              ],
                              null,
                              true
                            ),
                            model: {
                              value: line.account,
                              callback: function($$v) {
                                _vm.$set(line, "account", $$v)
                              },
                              expression: "line.account"
                            }
                          })
                        ],
                        1
                      )
                    ]),
                    _c("tr", [
                      _c(
                        "td",
                        [
                          _c("strong", [_vm._v("Organization")]),
                          line.org == null ||
                          (_vm.lodash.has(line.org, "is_exact_match") &&
                            !line.org.is_exact_match)
                            ? _c("span", { staticClass: "error-match" }, [
                                _c("i", {
                                  staticClass: "fa fa-exclamation-triangle"
                                }),
                                _vm._v("This is not an exact match.")
                              ])
                            : _vm._e(),
                          _c("v-select", {
                            staticClass: "mt-2",
                            attrs: {
                              clearable: false,
                              disabled: [2, 3, 4, 5, -5].includes(
                                _vm.invoice.qbyte_status.code
                              ),
                              label: "value_1",
                              options: _vm.org_idOptions
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "option",
                                  fn: function(option) {
                                    return [
                                      option.value_1 != null
                                        ? _c("span", [
                                            _vm._v(_vm._s(option.value_1))
                                          ])
                                        : _vm._e(),
                                      option.value_2 != null
                                        ? _c("span", [
                                            _vm._v("-" + _vm._s(option.value_2))
                                          ])
                                        : _vm._e(),
                                      option.value_3 != null
                                        ? _c("span", [
                                            _vm._v("-" + _vm._s(option.value_3))
                                          ])
                                        : _vm._e(),
                                      option.value_4 != null
                                        ? _c("span", [
                                            _vm._v("-" + _vm._s(option.value_4))
                                          ])
                                        : _vm._e()
                                    ]
                                  }
                                },
                                {
                                  key: "selected-option",
                                  fn: function(ref) {
                                    var value_1 = ref.value_1
                                    var value_2 = ref.value_2
                                    var value_3 = ref.value_3
                                    var value_4 = ref.value_4
                                    var option = ref.option
                                    var deselect = ref.deselect
                                    var multiple = ref.multiple
                                    var disabled = ref.disabled
                                    return [
                                      value_1 != null
                                        ? _c("span", [_vm._v(_vm._s(value_1))])
                                        : _vm._e(),
                                      value_2 != null
                                        ? _c("span", [
                                            _vm._v("-" + _vm._s(value_2))
                                          ])
                                        : _vm._e(),
                                      value_3 != null
                                        ? _c("span", [
                                            _vm._v("-" + _vm._s(value_3))
                                          ])
                                        : _vm._e(),
                                      value_4 != null
                                        ? _c("span", [
                                            _vm._v("-" + _vm._s(value_4))
                                          ])
                                        : _vm._e()
                                    ]
                                  }
                                }
                              ],
                              null,
                              true
                            ),
                            model: {
                              value: line.org,
                              callback: function($$v) {
                                _vm.$set(line, "org", $$v)
                              },
                              expression: "line.org"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "td",
                        [
                          _c("strong", [_vm._v("Activity Period:")]),
                          _c("v-select", {
                            staticClass: "mt-2 clear-allow",
                            attrs: {
                              disabled: [2, 3, 4, 5, -5].includes(
                                _vm.invoice.qbyte_status.code
                              ),
                              label: "value_1",
                              clearable: true,
                              options: _vm.afePeriodOptions
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "option",
                                  fn: function(option) {
                                    return [
                                      _vm._v(
                                        "\n                  " +
                                          _vm._s(
                                            _vm
                                              .$moment(option.value_1)
                                              .format("YYYY/MM")
                                          ) +
                                          "\n                "
                                      )
                                    ]
                                  }
                                },
                                {
                                  key: "selected-option",
                                  fn: function(ref) {
                                    var value_1 = ref.value_1
                                    var option = ref.option
                                    var deselect = ref.deselect
                                    var multiple = ref.multiple
                                    var disabled = ref.disabled
                                    return [
                                      _vm._v(
                                        "\n                  " +
                                          _vm._s(
                                            _vm
                                              .$moment(value_1)
                                              .format("YYYY/MM")
                                          ) +
                                          "\n                "
                                      )
                                    ]
                                  }
                                }
                              ],
                              null,
                              true
                            ),
                            model: {
                              value: line.activity_period,
                              callback: function($$v) {
                                _vm.$set(line, "activity_period", $$v)
                              },
                              expression: "line.activity_period"
                            }
                          })
                        ],
                        1
                      ),
                      _c("td", { attrs: { colspan: "2" } }, [
                        _c("strong", [_vm._v("Remarks:")]),
                        _c("div", { staticClass: "input-group mt-2" }, [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: line.remark,
                                expression: "line.remark"
                              }
                            ],
                            staticClass: "form-control",
                            attrs: {
                              type: "text",
                              readonly: [2, 3, 4, 5, -5].includes(
                                _vm.invoice.qbyte_status.code
                              ),
                              maxlength: "72",
                              placeholder: "Remarks"
                            },
                            domProps: { value: line.remark },
                            on: {
                              input: function($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.$set(line, "remark", $event.target.value)
                              }
                            }
                          })
                        ])
                      ])
                    ]),
                    _c("tr", [
                      _c("td", { attrs: { colspan: "6" } }, [
                        _c("div", { staticClass: "row" }, [
                          _c("div", { staticClass: "col-12 col-md-4" }, [
                            _c("strong", [_vm._v("Subtotal")]),
                            _c("br"),
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: line.subtotal,
                                  expression: "line.subtotal"
                                }
                              ],
                              staticClass: "mt-2 form-control",
                              attrs: { type: "text", readonly: "" },
                              domProps: { value: line.subtotal },
                              on: {
                                input: [
                                  function($event) {
                                    if ($event.target.composing) {
                                      return
                                    }
                                    _vm.$set(
                                      line,
                                      "subtotal",
                                      $event.target.value
                                    )
                                  },
                                  function($event) {
                                    return _vm.changeSubtotal(
                                      line.subtotal,
                                      aIndex
                                    )
                                  }
                                ]
                              }
                            })
                          ]),
                          _c("div", { staticClass: "col-12 col-md-4" }, [
                            _c("strong", [
                              _vm._v(
                                "Discount (" +
                                  _vm._s(_vm.discount_percentage) +
                                  "%)"
                              )
                            ]),
                            _c("br"),
                            _c("input", {
                              staticClass: "form-control mt-2",
                              attrs: { type: "text", readonly: "" },
                              domProps: {
                                value:
                                  "$" +
                                  _vm.formatPrice(
                                    line.subtotal *
                                      (_vm.discount_percentage / 100)
                                  )
                              }
                            })
                          ]),
                          _c("div", { staticClass: "col-12 col-md-4" }, [
                            _c("strong", [_vm._v("Discounted Total")]),
                            _c("br"),
                            _c("input", {
                              staticClass: "form-control mt-2",
                              attrs: { type: "text", readonly: "" },
                              domProps: {
                                value:
                                  "$" +
                                  _vm.formatPrice(
                                    line.subtotal -
                                      line.subtotal *
                                        (_vm.discount_percentage / 100)
                                  )
                              }
                            })
                          ])
                        ])
                      ])
                    ]),
                    _c("tr", [
                      _c("td", { attrs: { colspan: "6" } }, [
                        _c("div", { staticClass: "row" }, [
                          _vm.invoiceConfig.is_quickpay
                            ? _c("div", { staticClass: "col-12 col-md-4" }, [
                                _c("strong", [_vm._v("Quickpay Subtotal")]),
                                _c("br"),
                                _c("input", {
                                  staticClass: "mt-2 form-control",
                                  attrs: { type: "text", readonly: "" },
                                  domProps: {
                                    value:
                                      "$" +
                                      _vm.formatPrice(line.quickpay_subtotal)
                                  }
                                })
                              ])
                            : _vm._e(),
                          _vm.invoiceConfig.is_quickpay
                            ? _c("div", { staticClass: "col-12 col-md-4" }, [
                                _c("strong", [
                                  _vm._v(
                                    "Quickpay Discount (" +
                                      _vm._s(_vm.discount_percentage) +
                                      "%)"
                                  )
                                ]),
                                _c("br"),
                                _c("input", {
                                  staticClass: "form-control mt-2",
                                  attrs: { type: "text", readonly: "" },
                                  domProps: {
                                    value:
                                      "$" +
                                      _vm.formatPrice(
                                        line.quickpay_subtotal *
                                          (_vm.discount_percentage / 100)
                                      )
                                  }
                                })
                              ])
                            : _vm._e(),
                          _vm.invoiceConfig.is_quickpay
                            ? _c("div", { staticClass: "col-12 col-md-4" }, [
                                _c("strong", [
                                  _vm._v("Discounted Quickpay Total")
                                ]),
                                _c("br"),
                                _c("input", {
                                  staticClass: "form-control mt-2",
                                  attrs: { type: "text", readonly: "" },
                                  domProps: {
                                    value:
                                      "$" +
                                      _vm.formatPrice(
                                        line.quickpay_subtotal -
                                          line.quickpay_subtotal *
                                            (_vm.discount_percentage / 100)
                                      )
                                  }
                                })
                              ])
                            : _vm._e()
                        ])
                      ])
                    ])
                  ])
                ]
              )
            }),
            0
          ),
          _c(
            "b-row",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.invoiceConfigLoading,
                  expression: "invoiceConfigLoading"
                }
              ]
            },
            [
              _c("b-col", { staticClass: "col-12 col-lg-6 ml-auto" }, [
                _c("h2", [_vm._v("Totals")]),
                _c(
                  "table",
                  { staticClass: "table table-clear mt-1 total-table" },
                  [
                    _c("tbody", [
                      _c("tr", [
                        _c("td", { staticClass: "left" }, [
                          _c("strong", [_vm._v("Original Subtotal")])
                        ]),
                        _c("td", { staticClass: "right" }, [
                          _vm._v(
                            "$" +
                              _vm._s(
                                _vm.formatPrice(_vm.invoiceConfig.subtotal)
                              )
                          )
                        ])
                      ]),
                      _c("tr", [
                        _c("td", { staticClass: "left" }, [
                          _c("strong", [_vm._v("Discounts")])
                        ]),
                        _c("td", { staticClass: "right" }, [
                          _vm._v(
                            "$" +
                              _vm._s(
                                _vm.formatPrice(
                                  _vm.roundOff(
                                    Number(_vm.invoiceConfig.subtotal)
                                  ) - _vm.roundOff(_vm.subtotal)
                                )
                              )
                          )
                        ])
                      ]),
                      _c("tr", [
                        _c("td", { staticClass: "left" }, [
                          _c("strong", [_vm._v("Subtotal")])
                        ]),
                        _c("td", { staticClass: "right" }, [
                          _vm._v("$" + _vm._s(_vm.formatPrice(_vm.subtotal)))
                        ])
                      ]),
                      _c("tr", [
                        _c("td", { staticClass: "left" }, [
                          _c("strong", [_vm._v("Taxes")])
                        ]),
                        _c("td", { staticClass: "right" }, [
                          _vm._v("$" + _vm._s(_vm.formatPrice(_vm.tax)))
                        ])
                      ]),
                      _c("tr", [
                        _c("td", { staticClass: "left" }, [
                          _c("strong", [_vm._v("Total")])
                        ]),
                        _c("td", { staticClass: "right" }, [
                          _c("strong", [
                            _vm._v(
                              "$" +
                                _vm._s(
                                  _vm.formatPrice(
                                    _vm.roundOff(_vm.subtotal) +
                                      _vm.roundOff(_vm.tax)
                                  )
                                ) +
                                " CAD"
                            )
                          ])
                        ])
                      ])
                    ])
                  ]
                )
              ]),
              _vm.invoiceConfig.is_quickpay
                ? _c("b-col", { staticClass: "col-12 col-lg-6 ml-auto" }, [
                    _c("h2", [
                      _vm._v(
                        "Quickpay Totals (End Date: " +
                          _vm._s(
                            _vm._f("moment")(_vm.invoice.endtday, "MM/DD/YYYY")
                          ) +
                          ")"
                      )
                    ]),
                    _c(
                      "table",
                      { staticClass: "table table-clear mt-1 total-table" },
                      [
                        _c("tbody", [
                          _c("tr", [
                            _c("td", { staticClass: "left" }, [
                              _c("strong", [
                                _vm._v("Original Quickpay Subtotal")
                              ])
                            ]),
                            _c("td", { staticClass: "right" }, [
                              _vm._v(
                                "$" +
                                  _vm._s(
                                    _vm.formatPrice(
                                      _vm.invoiceConfig.quickpay
                                        .quickpay_subtotal
                                    )
                                  )
                              )
                            ])
                          ]),
                          _c("tr", [
                            _c("td", { staticClass: "left" }, [
                              _c("strong", [_vm._v("Discounts")])
                            ]),
                            _c("td", { staticClass: "right" }, [
                              _vm._v(
                                "$" +
                                  _vm._s(
                                    _vm.formatPrice(
                                      _vm.roundOff(
                                        Number(
                                          _vm.invoiceConfig.quickpay
                                            .quickpay_subtotal
                                        ) - _vm.roundOff(_vm.quickpay_subtotal)
                                      )
                                    )
                                  )
                              )
                            ])
                          ]),
                          _c("tr", [
                            _c("td", { staticClass: "left" }, [
                              _c("strong", [_vm._v("Quickpay Subtotal")])
                            ]),
                            _c("td", { staticClass: "right" }, [
                              _vm._v(
                                "$" +
                                  _vm._s(_vm.formatPrice(_vm.quickpay_subtotal))
                              )
                            ])
                          ]),
                          _c("tr", [
                            _c("td", { staticClass: "left" }, [
                              _c("strong", [_vm._v("Taxes")])
                            ]),
                            _c("td", { staticClass: "right" }, [
                              _vm._v(
                                "$" + _vm._s(_vm.formatPrice(_vm.quickpay_tax))
                              )
                            ])
                          ]),
                          _c("tr", [
                            _c("td", { staticClass: "left" }, [
                              _c("strong", [_vm._v("Quickpay Total")])
                            ]),
                            _c("td", { staticClass: "right" }, [
                              _c("strong", [
                                _vm._v(
                                  "$" +
                                    _vm._s(
                                      _vm.formatPrice(
                                        _vm.roundOff(_vm.quickpay_subtotal) +
                                          _vm.roundOff(_vm.quickpay_tax)
                                      )
                                    ) +
                                    "\n                  CAD"
                                )
                              ])
                            ])
                          ])
                        ])
                      ]
                    )
                  ])
                : _vm._e()
            ],
            1
          ),
          _vm.vendor_service_data.length > 0
            ? _c(
                "div",
                {
                  staticClass: "row mb-3 text-center d-none d-xl-flex",
                  staticStyle: { "margin-top": "35px" }
                },
                [
                  _c("div", { staticClass: "col-xl-1" }, [
                    _vm._v("\n         \n      ")
                  ]),
                  _c("div", { staticClass: "col-xl-2" }, [
                    _c("strong", [_vm._v("Service Name")])
                  ]),
                  _c("div", { staticClass: "col-xl-3" }, [
                    _c("strong", [_vm._v("Invoice Quantity")])
                  ]),
                  _c("div", { staticClass: "col-xl-2" }, [
                    _c("strong", [_vm._v("Qbyte Quantity")])
                  ]),
                  [0, 1, -1, -2, -3].includes(_vm.invoice.qbyte_status.code)
                    ? _c("div", { staticClass: "col-xl-2" }, [
                        _c("strong", [_vm._v("Code Name")])
                      ])
                    : _c("div", { staticClass: "col-xl-3" }, [
                        _c("strong", [_vm._v("Code Name")])
                      ])
                ]
              )
            : _vm._e(),
          _vm._l(_vm.vendor_service_data, function(v, i) {
            return _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.invoiceConfigLoading,
                    expression: "invoiceConfigLoading"
                  }
                ],
                staticClass: "row mb-2"
              },
              [
                v.qbyte_service_del_status == 0 &&
                [0, 1, -1, -2, -3].includes(_vm.invoice.qbyte_status.code)
                  ? _c("div", { staticClass: "col-xl-1 text-center" }, [
                      _c("h6", { staticClass: "mt-2 d-block d-xl-none" }, [
                        _vm._v("Remove")
                      ]),
                      _c("i", {
                        staticClass: "fa fa-minus-circle mt-2",
                        staticStyle: {
                          color: "#f86c6b",
                          "font-weight": "bold"
                        },
                        on: {
                          click: function($event) {
                            _vm.deleted_service.push(v.vsid)
                            _vm.vendor_service_data.splice(i, 1)
                          }
                        }
                      })
                    ])
                  : _vm._e(),
                v.qbyte_service_del_status == 0
                  ? _c("div", { staticClass: "col-xl-3" }, [
                      _c("h6", { staticClass: "mt-2 d-block d-xl-none" }, [
                        _vm._v("Service Name:")
                      ]),
                      _c("input", {
                        staticClass: "form-control",
                        attrs: {
                          type: "text",
                          readonly: "",
                          placeholder: "Volume"
                        },
                        domProps: { value: v.name }
                      })
                    ])
                  : _vm._e(),
                v.qbyte_service_del_status == 0 &&
                [0, 1, -1, -2, -3].includes(_vm.invoice.qbyte_status.code)
                  ? _c(
                      "div",
                      { staticClass: "col-xl-2" },
                      [
                        _c("h6", { staticClass: "mt-2 d-block d-xl-none" }, [
                          _vm._v("Invoice Quantity: ")
                        ]),
                        _c(
                          "b-input-group",
                          {
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "append",
                                  fn: function() {
                                    return [
                                      _c(
                                        "b-dropdown",
                                        {
                                          attrs: {
                                            variant: "outline-primary",
                                            text: v.uom
                                          }
                                        },
                                        _vm._l(_vm.uom_options, function(
                                          uom,
                                          j
                                        ) {
                                          return _c("b-dropdown-item", [
                                            _c(
                                              "span",
                                              {
                                                on: {
                                                  click: function($event) {
                                                    v.uom = uom.value
                                                  }
                                                }
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(uom.value) +
                                                    "(" +
                                                    _vm._s(uom.text) +
                                                    ")"
                                                )
                                              ]
                                            )
                                          ])
                                        }),
                                        1
                                      )
                                    ]
                                  },
                                  proxy: true
                                }
                              ],
                              null,
                              true
                            )
                          },
                          [
                            _c("b-form-input", {
                              attrs: { value: v.quantity, readonly: "" }
                            })
                          ],
                          1
                        )
                      ],
                      1
                    )
                  : _vm._e(),
                v.qbyte_service_del_status == 0 &&
                [2, 3, 4, 5, -5].includes(_vm.invoice.qbyte_status.code)
                  ? _c("div", { staticClass: "col-xl-3" }, [
                      _c(
                        "h6",
                        {
                          staticClass: "mt-2 d-block d-xl-none",
                          staticStyle: { "margin-left": "165px" }
                        },
                        [_vm._v("Invoice Quantity: ")]
                      ),
                      _c("input", {
                        staticClass: "form-control",
                        attrs: { type: "text", readonly: "" },
                        domProps: { value: v.quantity }
                      })
                    ])
                  : _vm._e(),
                v.qbyte_service_del_status == 0 &&
                [0, 1, -1, -2, -3].includes(_vm.invoice.qbyte_status.code)
                  ? _c(
                      "div",
                      { staticClass: "col-xl-2" },
                      [
                        _c("h6", { staticClass: "mt-2 d-block d-xl-none" }, [
                          _vm._v(" Qbyte Quantity:")
                        ]),
                        _c(
                          "b-input-group",
                          {
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "append",
                                  fn: function() {
                                    return [
                                      _c(
                                        "b-dropdown",
                                        {
                                          attrs: {
                                            variant: "outline-primary",
                                            text: v.qbyte_uom
                                          }
                                        },
                                        _vm._l(_vm.uom_conversion, function(
                                          uom,
                                          j
                                        ) {
                                          return _c("b-dropdown-item", [
                                            _c(
                                              "span",
                                              {
                                                on: {
                                                  click: function($event) {
                                                    v.qbyte_uom = uom.value
                                                  }
                                                }
                                              },
                                              [_vm._v(_vm._s(uom.value))]
                                            )
                                          ])
                                        }),
                                        1
                                      )
                                    ]
                                  },
                                  proxy: true
                                }
                              ],
                              null,
                              true
                            )
                          },
                          [
                            _c("b-form-input", {
                              model: {
                                value: v.qbyte_quantity,
                                callback: function($$v) {
                                  _vm.$set(v, "qbyte_quantity", $$v)
                                },
                                expression: "v.qbyte_quantity"
                              }
                            })
                          ],
                          1
                        )
                      ],
                      1
                    )
                  : _vm._e(),
                v.qbyte_service_del_status == 0 &&
                [2, 3, 4, 5, -5].includes(_vm.invoice.qbyte_status.code)
                  ? _c(
                      "div",
                      { staticClass: "col-xl-3" },
                      [
                        _c(
                          "h6",
                          {
                            staticClass: "mt-2 d-block d-xl-none",
                            staticStyle: { "margin-left": "71px" }
                          },
                          [_vm._v("Qbyte Quantity: ")]
                        ),
                        v.prod_name != null
                          ? _c("b-form-input", {
                              staticClass: "form-control",
                              attrs: {
                                value: v.qbyte_quantity + " " + v.qbyte_uom,
                                readonly: ""
                              }
                            })
                          : _c("b-form-input", {
                              staticClass: "form-control",
                              attrs: {
                                value: v.qbyte_quantity + " " + v.qbyte_uom,
                                readonly: ""
                              }
                            })
                      ],
                      1
                    )
                  : _vm._e(),
                v.qbyte_service_del_status == 0
                  ? _c("div", { staticClass: "col-xl-3" }, [
                      _c(
                        "h6",
                        {
                          staticClass: "mt-2 d-block d-xl-none",
                          staticStyle: { "margin-left": "105px" }
                        },
                        [_vm._v("Code Name:")]
                      ),
                      _c("input", {
                        staticClass: "form-control",
                        attrs: { type: "text", readonly: "" },
                        domProps: { value: v.prod_name }
                      })
                    ])
                  : _vm._e()
              ]
            )
          }),
          _c(
            "b-row",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.invoiceConfigLoading,
                  expression: "invoiceConfigLoading"
                }
              ]
            },
            [
              _c(
                "b-col",
                { staticClass: "mt-4 col-12 ml-auto", attrs: { lg: "4" } },
                [
                  _c("h5", [_vm._v("Voucher Remark:")]),
                  _c("textarea", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.invoiceConfig.voucher_remark,
                        expression: "invoiceConfig.voucher_remark"
                      }
                    ],
                    staticClass: "w-100 rounded border mt-2 mb-2",
                    attrs: {
                      name: "comments",
                      readonly: [2, 3, 4, 5, -5].includes(
                        _vm.invoice.qbyte_status.code
                      ),
                      maxlength: "40",
                      rows: "5"
                    },
                    domProps: { value: _vm.invoiceConfig.voucher_remark },
                    on: {
                      input: function($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.$set(
                          _vm.invoiceConfig,
                          "voucher_remark",
                          $event.target.value
                        )
                      }
                    }
                  })
                ]
              ),
              _c(
                "b-col",
                { staticClass: "mt-4 col-12 ml-auto", attrs: { lg: "4" } },
                [
                  _c("h5", [_vm._v("Footnote:")]),
                  _c("textarea", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.invoiceConfig.footnote,
                        expression: "invoiceConfig.footnote"
                      }
                    ],
                    staticClass: "w-100 rounded border mt-2 mb-2",
                    attrs: {
                      name: "comments",
                      maxlength: "2000",
                      readonly: [2, 3, 4, 5, -5].includes(
                        _vm.invoice.qbyte_status.code
                      ),
                      rows: "5"
                    },
                    domProps: { value: _vm.invoiceConfig.footnote },
                    on: {
                      input: function($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.$set(
                          _vm.invoiceConfig,
                          "footnote",
                          $event.target.value
                        )
                      }
                    }
                  })
                ]
              ),
              _c(
                "b-col",
                { staticClass: "mt-4 col-12 ml-auto", attrs: { lg: "4" } },
                [
                  _c("h5", [_vm._v("Remark:")]),
                  _c("textarea", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.invoiceConfig.invoice_remark,
                        expression: "invoiceConfig.invoice_remark"
                      }
                    ],
                    staticClass: "w-100 rounded border mt-2 mb-2",
                    attrs: {
                      name: "comments",
                      maxlength: "120",
                      readonly: [2, 3, 4, 5, -5].includes(
                        _vm.invoice.qbyte_status.code
                      ),
                      rows: "5"
                    },
                    domProps: { value: _vm.invoiceConfig.invoice_remark },
                    on: {
                      input: function($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.$set(
                          _vm.invoiceConfig,
                          "invoice_remark",
                          $event.target.value
                        )
                      }
                    }
                  })
                ]
              )
            ],
            1
          ),
          _c(
            "b-row",
            [
              _c(
                "b-modal",
                {
                  attrs: {
                    id: "add-qbyte-comments",
                    "no-close-on-backdrop": true,
                    title: "Add Comment",
                    size: "xl"
                  },
                  on: {
                    ok: function($event) {
                      return _vm.switchAction()
                    }
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "modal-footer",
                      fn: function(ref) {
                        var ok = ref.ok
                        var cancel = ref.cancel
                        return [
                          _c(
                            "b-button",
                            {
                              attrs: {
                                size: "sm",
                                variant: "success",
                                disabled: _vm.comments.trim().length === 0
                              },
                              on: {
                                click: function($event) {
                                  return ok()
                                }
                              }
                            },
                            [_vm._v("\n            OK\n          ")]
                          ),
                          _c(
                            "b-button",
                            {
                              attrs: { size: "sm", variant: "danger" },
                              on: {
                                click: function($event) {
                                  return cancel()
                                }
                              }
                            },
                            [_vm._v("\n            Cancel\n          ")]
                          )
                        ]
                      }
                    }
                  ])
                },
                [
                  _c("h5", [_vm._v("Please add a comment.")]),
                  _c("textarea", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.comments,
                        expression: "comments"
                      }
                    ],
                    staticClass: "w-100 rounded border mt-2",
                    attrs: { name: "comments", rows: "3" },
                    domProps: { value: _vm.comments },
                    on: {
                      input: function($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.comments = $event.target.value
                      }
                    }
                  }),
                  _c(
                    "i",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.comments.trim().length === 0,
                          expression: "comments.trim().length === 0"
                        }
                      ],
                      staticClass: "fa fa-exclamation-triangle"
                    },
                    [_vm._v("Comment is require.")]
                  )
                ]
              ),
              _c(
                "b-col",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.invoiceConfigLoading,
                      expression: "invoiceConfigLoading"
                    }
                  ],
                  staticClass: "mt-4 col-12 ml-auto",
                  staticStyle: {
                    display: "flex",
                    "justify-content": "flex-end"
                  },
                  attrs: { lg: "8" }
                },
                [
                  [0, 1, -1, -2, -3].includes(_vm.invoice.qbyte_status.code)
                    ? _c(
                        "button",
                        {
                          staticClass: "btn btn-success mr-2 mt-3 mb-3",
                          attrs: {
                            type: "submit",
                            disabled: !_vm.invoiceConfig.isValid
                          },
                          on: {
                            click: function($event) {
                              return _vm.submitInvoice(0)
                            }
                          }
                        },
                        [
                          _c("i", { staticClass: "fa fa-check" }),
                          _vm._v(" Submit")
                        ]
                      )
                    : _vm._e(),
                  [0, 1, -1, -2, -3].includes(_vm.invoice.qbyte_status.code)
                    ? _c(
                        "button",
                        {
                          staticClass: "btn btn-blue mt-3 mb-3 mr-2",
                          attrs: {
                            type: "submit",
                            disabled: !_vm.invoiceConfig.isValid
                          },
                          on: {
                            click: function($event) {
                              return _vm.submitInvoice(1)
                            }
                          }
                        },
                        [
                          _c("i", { staticClass: "fa fa-save" }),
                          _vm._v(" Save As Draft")
                        ]
                      )
                    : _vm._e(),
                  [0, 1, -1, -2, -3].includes(_vm.invoice.qbyte_status.code)
                    ? _c(
                        "button",
                        {
                          staticClass: "btn btn-warning mt-3 mb-3 mr-2",
                          staticStyle: { color: "white" },
                          attrs: {
                            type: "submit",
                            disabled: !_vm.invoiceConfig.isValid
                          },
                          on: {
                            click: function($event) {
                              return _vm.submitInvoice(2)
                            }
                          }
                        },
                        [
                          _c("i", { staticClass: "fa fa-edit" }),
                          _vm._v(" Save Changes")
                        ]
                      )
                    : _vm._e(),
                  [0, 1, -1, -2, -3].includes(_vm.invoice.qbyte_status.code)
                    ? _c(
                        "b-dropdown",
                        {
                          staticClass: "mt-3 mb-3 mr-2",
                          attrs: { variant: "primary", text: "Send Back To" }
                        },
                        [
                          _c(
                            "b-dropdown-item",
                            {
                              directives: [
                                {
                                  name: "b-modal",
                                  rawName: "v-b-modal.add-qbyte-comments",
                                  modifiers: { "add-qbyte-comments": true }
                                }
                              ],
                              on: {
                                click: function($event) {
                                  _vm.submissionAction = 0
                                }
                              }
                            },
                            [_vm._v("Well Site Supervisor\n          ")]
                          ),
                          _c(
                            "b-dropdown-item",
                            {
                              directives: [
                                {
                                  name: "b-modal",
                                  rawName: "v-b-modal.add-qbyte-comments",
                                  modifiers: { "add-qbyte-comments": true }
                                }
                              ],
                              on: {
                                click: function($event) {
                                  _vm.submissionAction = 1
                                }
                              }
                            },
                            [_vm._v("Project Manager\n          ")]
                          ),
                          _c(
                            "b-dropdown-item",
                            {
                              directives: [
                                {
                                  name: "b-modal",
                                  rawName: "v-b-modal.add-qbyte-comments",
                                  modifiers: { "add-qbyte-comments": true }
                                }
                              ],
                              on: {
                                click: function($event) {
                                  _vm.submissionAction = 2
                                }
                              }
                            },
                            [_vm._v("Manager")]
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  [0, 1, -1, -2, -3].includes(_vm.invoice.qbyte_status.code)
                    ? _c(
                        "button",
                        {
                          directives: [
                            {
                              name: "b-modal",
                              rawName: "v-b-modal.add-qbyte-comments",
                              modifiers: { "add-qbyte-comments": true }
                            }
                          ],
                          staticClass: "btn btn-danger mr-2 mt-3 mb-3",
                          on: {
                            click: function($event) {
                              _vm.submissionAction = 3
                            }
                          }
                        },
                        [
                          _c("i", { staticClass: "fa fa-times-circle" }),
                          _vm._v("\n          Reject")
                        ]
                      )
                    : _vm._e(),
                  [2, 3].includes(_vm.invoice.qbyte_status.code)
                    ? _c(
                        "button",
                        {
                          staticClass: "btn btn-danger mr-2 mt-3 mb-3",
                          on: {
                            click: function($event) {
                              return _vm.updateInvoiceStatus(-5)
                            }
                          }
                        },
                        [
                          _c("i", { staticClass: "fa fa-trash" }),
                          _vm._v(" Delete Invoice")
                        ]
                      )
                    : _vm._e(),
                  [2, 3].includes(_vm.invoice.qbyte_status.code)
                    ? _c(
                        "button",
                        {
                          staticClass: "btn btn-secondary mr-2 mt-3 mb-3",
                          on: {
                            click: function($event) {
                              return _vm.updateInvoiceStatus(0)
                            }
                          }
                        },
                        [
                          _c("i", { staticClass: "fa fa-question" }),
                          _vm._v(" Unprocess Invoice")
                        ]
                      )
                    : _vm._e()
                ],
                1
              )
            ],
            1
          ),
          _vm.invoiceConfigLoading
            ? _c(
                "b-row",
                [
                  _c(
                    "b-col",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: !_vm.invoiceConfig.isValid,
                          expression: "!invoiceConfig.isValid"
                        }
                      ],
                      staticClass: "mt-4 col-12 ml-auto",
                      staticStyle: {
                        display: "flex",
                        "justify-content": "flex-end",
                        color: "red",
                        cursor: "pointer"
                      },
                      attrs: { lg: "8" }
                    },
                    [
                      _c(
                        "span",
                        {
                          directives: [
                            {
                              name: "b-tooltip",
                              rawName: "v-b-tooltip.hover",
                              modifiers: { hover: true }
                            }
                          ],
                          attrs: {
                            title:
                              "This invoice has incorrect coding percentage or contains no codings"
                          }
                        },
                        [
                          _c("i", {
                            staticClass: "fa fa-exclamation-triangle mr-2"
                          }),
                          _vm._v(" Invoice Coding is Incorrect.")
                        ]
                      )
                    ]
                  )
                ],
                1
              )
            : _vm._e(),
          _c(
            "b-row",
            [
              _c(
                "b-col",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.invoiceConfigLoading,
                      expression: "invoiceConfigLoading"
                    }
                  ],
                  staticClass: "mt-4 col-12 ml-auto",
                  staticStyle: {
                    display: "flex",
                    "justify-content": "flex-end"
                  },
                  attrs: { lg: "8" }
                },
                [
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.invoiceError,
                          expression: "invoiceError"
                        }
                      ],
                      staticClass: "d-block"
                    },
                    [
                      _c("i", {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.invoiceError,
                            expression: "invoiceError"
                          }
                        ],
                        staticClass: "fa fa-exclamation-triangle"
                      }),
                      _c(
                        "span",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.invoiceError,
                              expression: "invoiceError"
                            }
                          ],
                          staticClass: "help"
                        },
                        [
                          _vm._v(
                            "Invoice Error. Please fix invoice or contact admin."
                          )
                        ]
                      )
                    ]
                  ),
                  _vm.errorMessage !== ""
                    ? _c(
                        "div",
                        {
                          staticClass: "d-block",
                          staticStyle: { color: "red" }
                        },
                        [
                          _c("i", {
                            staticClass: "fa fa-exclamation-triangle"
                          }),
                          _c("span", { staticClass: "help" }, [
                            _vm._v(_vm._s(_vm.errorMessage))
                          ]),
                          _c("br"),
                          _vm.errorMessage === "Invoice Not Found."
                            ? _c("span", [
                                _vm._v(
                                  "Invoice is recently edited. Please reload the invoice for\n            submission."
                                )
                              ])
                            : _vm._e()
                        ]
                      )
                    : _vm._e()
                ]
              )
            ],
            1
          ),
          _vm.recent_submission != null
            ? _c(
                "b-row",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.invoiceConfigLoading,
                      expression: "invoiceConfigLoading"
                    }
                  ]
                },
                [
                  _c("b-col", { attrs: { lg: "12" } }, [
                    _c("h2", [_vm._v("Qbyte Response:")]),
                    _c("hr"),
                    _c("h5", [
                      _vm._v(
                        "Submitted At: " +
                          _vm._s(
                            _vm._f("moment")(
                              _vm.recent_submission.submitted_at,
                              "MM/DD/YYYY hh:mm:ss A"
                            )
                          )
                      )
                    ]),
                    _vm.recent_submission.status.text == "loaded"
                      ? _c("h5", [
                          _vm._v(
                            "Loaded At: " +
                              _vm._s(
                                _vm._f("moment")(
                                  _vm.recent_submission.loaded_at,
                                  "MM/DD/YYYY hh:mm:ss A"
                                )
                              )
                          )
                        ])
                      : _vm._e(),
                    _vm.recent_submission.status.text == "posted" ||
                    (_vm.recent_submission.status.text == "failed" &&
                      !Array.isArray(_vm.recent_submission.errors[0])) ||
                    _vm.recent_submission.status.text == "submitted"
                      ? _c("h5", [
                          _vm._v(
                            "\n          Report ID: " +
                              _vm._s(_vm.recent_submission.report_id)
                          )
                        ])
                      : _vm._e(),
                    _vm.recent_submission.status.text == "posted"
                      ? _c("h5", [
                          _vm._v(
                            "Posted At: " +
                              _vm._s(
                                _vm._f("moment")(
                                  _vm.recent_submission.posted_at,
                                  "MM/DD/YYYY hh:mm:ss A"
                                )
                              )
                          )
                        ])
                      : _vm._e(),
                    _vm.recent_submission.status.text == "processed"
                      ? _c("h5", [
                          _vm._v(
                            "Processed At: " +
                              _vm._s(
                                _vm._f("moment")(
                                  _vm.recent_submission.processed_at,
                                  "MM/DD/YYYY hh:mm:ss A"
                                )
                              )
                          )
                        ])
                      : _vm._e(),
                    _vm.recent_submission.archived_at
                      ? _c("h5", [
                          _vm._v(
                            "Archived At: " +
                              _vm._s(
                                _vm._f("moment")(
                                  _vm.recent_submission.archived_at,
                                  "MM/DD/YYYY hh: mm: ss A"
                                )
                              )
                          )
                        ])
                      : _vm._e(),
                    _vm.recent_submission.voucher
                      ? _c("div", [
                          _c("h5", [_vm._v("Voucher Info:")]),
                          _c("ul", [
                            _vm.recent_submission.voucher.invoice_id
                              ? _c("li", [
                                  _vm._v(
                                    "Invoice ID: " +
                                      _vm._s(
                                        _vm.recent_submission.voucher.invoice_id
                                      ) +
                                      "\n            "
                                  )
                                ])
                              : _vm._e(),
                            _vm.recent_submission.voucher.payment_status
                              ? _c("li", [
                                  _vm._v(
                                    "Payment Status:\n              " +
                                      _vm._s(
                                        _vm.recent_submission.voucher
                                          .payment_status
                                      )
                                  )
                                ])
                              : _vm._e(),
                            _vm.recent_submission.voucher.voucher_id
                              ? _c("li", [
                                  _vm._v(
                                    "Voucher ID: " +
                                      _vm._s(
                                        _vm.recent_submission.voucher.voucher_id
                                      ) +
                                      "\n            "
                                  )
                                ])
                              : _vm._e(),
                            _vm.recent_submission.voucher.voucher_num
                              ? _c("li", [
                                  _vm._v(
                                    "Voucher Number: " +
                                      _vm._s(
                                        _vm.recent_submission.voucher
                                          .voucher_num
                                      ) +
                                      "\n            "
                                  )
                                ])
                              : _vm._e(),
                            _vm.recent_submission.voucher.voucher_status
                              ? _c("li", [
                                  _vm._v(
                                    "Voucher Status:\n              " +
                                      _vm._s(
                                        _vm.recent_submission.voucher
                                          .voucher_status
                                      )
                                  )
                                ])
                              : _vm._e()
                          ])
                        ])
                      : _vm._e(),
                    _vm.recent_submission.errors &&
                    Array.isArray(_vm.recent_submission.errors[0])
                      ? _c("div", [
                          _c("h5", [_vm._v("Qbyte Validation: ")]),
                          _c(
                            "table",
                            {
                              staticClass: "mt-2",
                              attrs: { id: "submission-msg" }
                            },
                            [
                              _c("thead", [
                                _c("tr", [
                                  _c("th", [_vm._v("QBYTE TABLE NAME")]),
                                  _c("th", [_vm._v("INVOICE ID")]),
                                  _c("th", [_vm._v("VOUCHER ID")]),
                                  _c("th", [_vm._v("CREATE USER")]),
                                  _c("th", [_vm._v("CREATE DATE")]),
                                  _c("th", [_vm._v("INVOICE AMOUNT")])
                                ])
                              ]),
                              _vm._l(_vm.recent_submission.errors[0], function(
                                val
                              ) {
                                return _c("tbody", [
                                  _c("tr", [
                                    _c("td", [
                                      _vm._v(
                                        _vm._s(
                                          "TEMP_INVC_NUM" in val
                                            ? "TEMP_INVOICES"
                                            : "INVOICES"
                                        )
                                      )
                                    ]),
                                    _c("td", [_vm._v(_vm._s(val.INVC_ID))]),
                                    _c("td", [_vm._v(_vm._s(val.VOUCHER_ID))]),
                                    _c("td", [_vm._v(_vm._s(val.CREATE_USER))]),
                                    _c("td", [
                                      _vm._v(
                                        _vm._s(
                                          _vm._f("moment")(
                                            val.CREATE_DATE,
                                            "MM/DD/YYYY hh:mm:ss A"
                                          )
                                        )
                                      )
                                    ]),
                                    _c("td", [_vm._v(_vm._s(val.INVC_AMT))])
                                  ])
                                ])
                              })
                            ],
                            2
                          ),
                          _c(
                            "span",
                            {
                              staticClass: "mt-2",
                              staticStyle: { color: "red" }
                            },
                            [_vm._v("Above invoices already exist in qbyte.")]
                          )
                        ])
                      : _vm.recent_submission.errors
                      ? _c("div", [
                          _c("h5", [
                            _vm._v(
                              "Failed At: " +
                                _vm._s(
                                  _vm._f("moment")(
                                    _vm.recent_submission.failed_at,
                                    "MM/DD/YYYY hh:mm:ss A"
                                  )
                                )
                            )
                          ]),
                          _c("h5", [_vm._v("Errors:")]),
                          _c(
                            "ul",
                            { staticStyle: { color: "#b54040" } },
                            _vm._l(_vm.recent_submission.errors, function(
                              error
                            ) {
                              return _c("li", [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(error) +
                                    "\n            "
                                )
                              ])
                            }),
                            0
                          )
                        ])
                      : _vm._e()
                  ])
                ],
                1
              )
            : _vm._e(),
          _c("hr"),
          _c("InvoiceHistory", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.invoiceConfigLoading,
                expression: "invoiceConfigLoading"
              }
            ],
            attrs: { history: _vm.invoice }
          })
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }